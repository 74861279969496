import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Profile from "../views/Profile.vue";
import MyReservation from "../views/MyReservation.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title:
        "Matchday - แพลตฟอร์มจองสนามกีฬาที่ใหญ่ที่ในประเทศไทย"
    }
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
    meta: {
      reload: true,
      title: "Matchday - เกี่ยวกับเรา"
    }
  },
  {
    path: "/partners",
    name: "Partners",
    component: () => import("../views/Partners2.vue"),
    meta: {
      title: "Matchday - เป็นพาร์ทเนอร์กับเรา"
    }
  },
  {
    path: "/news",
    name: "News",
    component: () => import("../views/NewsBlog.vue"),
    meta: {
      title: "Matchday - ข่าวสาร"
    }
  },
  {
    path: "/post/:id",
    name: "news-reader",
    component: () => import("../views/NewsReader.vue")
  },
  {
    path: "/voucher-deal",
    name: "Voucher",
    component: () => import("../views/Store.vue"),
    meta: {
      title: "Matchday - คูปองโปรโมชั่น"
    }
  },
  {
    path: "/voucher/:bundle_id",
    name: "Store_payment",
    component: () => import("../views/StorePayment.vue"),
    meta: {
      title: "Matchday - ชำระเงินคูปองโปรโมชั่น"
    }
  },
  {
    path: '/ais-deal',
    name: 'AIS',
    component: () => import('../views/AIS.vue'),
    meta: {
      title: 'Matchday - คูปองโปรโมชั่น AIS'
    }
  },
  {
    path: "/payment-confirmation/:refno_id",
    name: "Store_payment_complete",
    component: () => import("../views/StorePaymentComplete.vue"),
    meta: {
      title: "Matchday - ชำระเงินสำเร็จ"
    }
  },
  // {
  //   path: "/provider/:provider_id",
  //   name: "Provider",
  //   component: () => import("../views/Provider.vue"),
  //   meta: {
  //     title: "Matchday - จองสนาม"
  //   }
  // }, //
  {
    path: "/privacy-policy",
    name: "Privacy",
    component: () => import("../views/Privacy.vue"),
    meta: {
      title: "Matchday - นโยบายความเป็นส่วนตัว"
    },
    props: {
      navbar: false
    }
  },
  {
    path: "/arena-terms",
    name: "Arena-Privacy",
    component: () => import("../views/arena_term.vue"),
    meta: {
      title: "Matchday Arena - ข้อตกลงและเงื่อนไขการใช้งาน"
    },
    props: {
      navbar: false
    }
  },
  {
    path: "/terms",
    name: "Terms",
    component: () => import("../views/Terms.vue"),
    meta: {
      title: "Matchday - ข้อตกลงและเงื่อนไขการใช้งาน"
    },
    props: {
      navbar: false
    }
  },
  {
    path: "/create",
    name: "Create",
    component: () => import("../views/CreateMatch.vue"),
    meta: {
      title: "Matchday - สร้างแมทช์"
    }
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("../views/404.vue"),
    meta: {
      title:
        "Matchday - แพลตฟอร์มจองสนามกีฬาที่ใหญ่ที่ในประเทศไทย"
    },
    props: {
      navbar: false,
      footer: false
    }
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: {
      title: "Matchday - Profile"
    }
  },
  {
    path: "/my-reservation",
    meta: {
      title: "Matchday - การจองของฉัน"
    },
    component: MyReservation
  },
  {
    path: "/payment-status",
    name: "Payment Status",
    component: () => import("../views/PaymentStatus.vue"),
    meta: {
      title: "Matchday - สถานะการจอง"
    }
  },
  {
    path: "/forget-password",
    name: "Forget Password",
    component: () => import("../views/ForgetPassword.vue"),
    meta: {
      title: "Matchday - สถานะการจอง"
    }
  },
  {
    path: '/app-ads.txt',
    name: "ads",
    component: () => import("../views/file_host.vue"),
    meta: {
      title: "Matchday - สถานะการจอง"
    }
  },
  {
    path: '/robots.txt',
    name: "robots",
    component: () => import("../views/file_host2.vue"),
    meta: {
      title: "Matchday - สถานะการจอง"
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
